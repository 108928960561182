import React from 'react'
import { Fade } from 'react-awesome-reveal'
import Layout from '../components/layout'
import { ProductSection, Section } from '../components/section'
import Seo from '../components/seo'

const ThankyouPage = () => {
	return (
		<Layout>
			<Seo
				title="Your EBook is on its way!"
				description="Thank you for signing up for Rapid Fire Options free EBook."
			/>
			<Section sectionTitle="Your EBook is on its way!">
				<Fade triggerOnce>
					<p>
						Thank you for subscribing to the Wealthstyle Newsletter, we're
						always eager to put out fresh and insightful content that will help
						you grow your business.
					</p>
					<br />
					<p>
						Your ebook is on its way! We'll be sending it in the next few
						minutes and hope that you enjoy it!
					</p>
					<br />
					<p className="pb-5">
						In case you're interested in upgrading your account, we have a
						limited time offer for new subscribers. Please check out the deals
						below to upgrade to premium.
					</p>
					<p>
						Sincerely,
						<br />
						The Rapid Fire Options team
					</p>
				</Fade>
			</Section>
			<ProductSection />
		</Layout>
	)
}

export default ThankyouPage
